<template>
  <div>
    <div v-if="products.data && products.data.length" class="position-table">
      <md-table
        v-model="products.data"
        :md-sort-fn="customSort"
        :md-sort.sync="customSortBy"
        :md-sort-order.sync="customSortOrder"
      >
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          :class="{ disabled: selectedId && selectedId !== item.id }"
          @click="selectedId = !selectedId ? item.id : null"
        >
          <md-table-cell>
            <md-checkbox
              :value="selectedId !== item.id"
              :disabled="selectedId && selectedId !== item.id"
              @change="selectedId = !selectedId ? item.id : null"
            />
          </md-table-cell>

          <md-table-cell
            md-label="Номер товара"
            md-sort-by="id"
            class="custom-field"
          >
            <span>{{ item.id }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Наименование"
            md-sort-by="name"
            class="custom-field"
          >
            <span>{{ item.name }}</span>
          </md-table-cell>

          <md-table-cell md-label="Категория" class="custom-field">
            <span>{{ getCategories(item.categories) }}</span>
          </md-table-cell>

          <md-table-cell md-label="Бренд" class="custom-field">
            <span>{{ item.brand.name }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Дата добавления"
            md-sort-by="created_at"
            class="custom-field"
          >
            <span>{{ getDate(item.created_at) }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Новая цена"
            md-sort-by="price"
            class="custom-field"
          >
            <span>{{ item.price }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Старая цена"
            md-sort-by="old_price"
            class="custom-field"
          >
            <span>{{ item.old_price }}</span>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <div v-if="products.meta.last_page > 1" class="pagination">
        <md-button
          class="md-primary"
          :disabled="products.meta.current_page === 1"
          @click="$emit('prev')"
        >
          <md-icon>navigate_before</md-icon>
        </md-button>
        <div class="pages">
          <span>{{ products.meta.current_page }}</span>
          /
          <span>{{ products.meta.last_page }}</span>
        </div>
        <md-button
          class="md-primary"
          :disabled="products.meta.current_page === products.meta.last_page"
          @click="$emit('next')"
        >
          <md-icon>navigate_next</md-icon>
        </md-button>
      </div>
    </div>
    <div v-else class="my-empty empty">Пусто</div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { formatISODate } from "@/helpers/formatDate";

export default {
  data() {
    return {
      customSortBy: "id",
      customSortOrder: "asc",
      selectedId: null,
    };
  },

  computed: {
    ...mapState({
      products: (state) => state.products.products,
    }),
  },

  watch: {
    selectedId(value) {
      this.$emit("select", value);
    },
  },

  methods: {
    customSort(value) {
      this.$emit("sort", {
        sortBy: this.customSortBy,
        sortOrder: this.customSortOrder,
      });

      return value;
    },

    getCategories(categories) {
      return categories
        .flat()
        .map((item) => item.category.name)
        .join(", ");
    },

    getDate(isoDate) {
      return formatISODate(isoDate);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;

  .pages {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
}

::v-deep {
  .md-table-sortable-icon {
    left: unset !important;
    right: 8px !important;
  }

  .md-table-row {
    cursor: pointer;

    &.disabled {
      cursor: auto;
      opacity: 0.5;
    }
  }
}
</style>